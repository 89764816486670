import styled from "styled-components";

export const LeftTopBarContainer = styled.div<{ $customerColor?: string }>`
  width: 100%;
  height: 60px;
  background-color: ${props => props.$customerColor};
  display: flex;
  align-items: center;
  padding: 5px 20px;
  > .back_icon {
    border-radius: 50px;
    padding: 5px;
    transition: ease-in-out 0.2s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`;

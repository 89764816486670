import { Route, Routes } from "react-router-dom";
import { routesManager } from "./routesManager";
import { AnalyticsPage, ChatPage } from "../pages";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={routesManager.getChatRoute()} element={<ChatPage />} />
      <Route path={routesManager.getAnalyticsRoute()} element={<AnalyticsPage />} />
    </Routes>
  );
};

export { AppRoutes };

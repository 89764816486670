import { useState } from "react";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import { Avatar, Typography } from "antd";
import { BubbleContainer, BubbleContent, MessageBox, ReferenceContainer } from "./styled";
import { InfoCircleOutlined } from "@ant-design/icons";
import { IReference } from "../../../services";
import { ReferencesModal } from "./ReferecesModal";

interface IMessageBubbleProps {
  markdownText: string;
  float?: "left" | "right";
  references?: IReference[];
  date_time?: string;
}

const MessageBubble = ({ references, float, markdownText, date_time }: IMessageBubbleProps) => {
  const [showReferences, setShowReferences] = useState(false);

  const isBotBubble = float === "left" ? "bot" : "user";

  return (
    <BubbleContainer>
      <BubbleContent $owner={isBotBubble}>
        {isBotBubble === "bot" ? (
          <Avatar
            src="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/it1eyro00mwll3cmlgdv"
            className="bot_avatar"
            style={{
              backgroundColor: "#000",
              width: "50px",
              height: "50px",
              position: "absolute",
              left: "-55px",
              top: 0,
              zIndex: 90,
            }}
          />
        ) : null}
        <MessageBox>
          <ReactMarkdown>{markdownText}</ReactMarkdown>
        </MessageBox>
        {references && references.length ? (
          <ReferenceContainer>
            <div
              onClick={() => setShowReferences(prev => !prev)}
              style={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ReferencesModal references={references} isOpen={showReferences} />
              <Typography style={{ color: "#153651" }}>View leveraged sources</Typography>
              <InfoCircleOutlined style={{ color: "#153651" }} />
            </div>
          </ReferenceContainer>
        ) : null}
        {date_time && (
          <Typography.Text className="message_time" type="secondary" style={{ fontSize: 12 }}>
            {moment(date_time).fromNow()}
          </Typography.Text>
        )}
      </BubbleContent>
    </BubbleContainer>
  );
};

export { MessageBubble };

/* eslint-disable indent */
import { ChatCard, ChatTitle } from "./styled";
import { DeleteOutlined } from "@ant-design/icons";

interface IChatItemProps {
  id: number;
  email?: string;
  subtitle: string;
  date: string;
  isSelected: boolean;
  onClick({ sessionId }: { sessionId: number }): void;
  onDelete({ sessionId }: { sessionId: number }): Promise<void>;
  anonymous?: boolean;
}

const ChatItem = ({
  id,
  subtitle,
  isSelected,
  anonymous,
  onClick: handleClick,
  onDelete: handleDelete,
}: IChatItemProps) => {
  return (
    <ChatCard onClick={() => handleClick({ sessionId: id })} $isSelected={isSelected ? 1 : 0}>
      {/* <ChatBubbleOutlineOutlinedIcon style={{ color: "#3E4352", width: "16px", height: "16px" }} /> */}
      <ChatTitle $isSelected={isSelected ? 1 : 0}>
        {subtitle}
        <div className="gradient"></div>
      </ChatTitle>
      {!anonymous && isSelected ? (
        <DeleteOutlined
          className="delete_btn"
          onClick={e => {
            e.stopPropagation();
            handleDelete({ sessionId: id });
          }}
          style={{
            cursor: "pointer",
            color: "#3E4352",
            width: 20,
          }}
        />
      ) : null}
    </ChatCard>
  );
};

export { ChatItem };

import { LeftTopBarContainer } from "./styled";
import { Avatar, Typography } from "antd";

const LeftTopBar = () => {
  return (
    <LeftTopBarContainer $customerColor="#000">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 15,
          width: "80%",
          marginLeft: 30,
        }}
      >
        <Avatar
          src="https://icodrops.com/wp-content/uploads/2022/11/y7t0sll0_400x400.jpg"
          size={40}
        />
        <Typography.Title level={4} style={{ color: "#fff", marginBottom: "0" }}>
          Spindl AI
        </Typography.Title>
      </div>
    </LeftTopBarContainer>
  );
};

export { LeftTopBar };

import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

if (!BASE_API_URL) {
  throw new Error("Environment variable REACT_APP_BASE_API_URL must be defined");
}

const api = axios.create({
  baseURL: `${BASE_API_URL}/api/v1`,
});

export { api };

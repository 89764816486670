import { useEffect, useState } from "react";
import { BarChart } from "../../components/Charts/BarChart";
import { Button, Tabs, TabsProps, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { routesManager } from "../../routes/routesManager";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { analyticsService } from "../../services/analytics";

const AnalyticsPage = () => {
  const [resourceData, setResourceData] = useState();
  const [publisherData, setPublisherData] = useState();
  const [activeKey, setActiveKey] = useState("1");
  const navigator = useNavigate();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Usage by Publisher",
    },
    {
      key: "2",
      label: "Usage by Article",
    },
  ];

  useEffect(() => {
    (async () => {
      const data = await analyticsService.getResourceUsage();
      setResourceData(data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const data = await analyticsService.getPublisherUsage();
      setPublisherData(data);
    })();
  }, []);

  return (
    <div style={{ height: "100vh", padding: 30, backgroundColor: "#f8fcff" }}>
      <Button
        onClick={() => navigator(routesManager.getChatRoute())}
        size="large"
        icon={<ArrowLeftOutlined />}
        style={{ fontSize: "14px", margin: "10px 0", float: "left" }}
      >
        <Typography.Text>Back</Typography.Text>
      </Button>

      <Typography style={{ marginBottom: 20, fontSize: 30, color: "#555", textAlign: "center" }}>
        Explore content usage
      </Typography>
      <div
        style={{
          border: "1px solid #ddd",
          borderRadius: 10,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 10,
          paddingBottom: 20,
        }}
      >
        <Tabs defaultActiveKey="1" items={items} onChange={key => setActiveKey(key)} />
        <BarChart data={activeKey === "1" ? publisherData : resourceData} />
      </div>
    </div>
  );
};

export { AnalyticsPage };

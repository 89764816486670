import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

interface CustomTickProps {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
}

const CustomizedAxisTick = ({ x, y, payload }: CustomTickProps) => {
  if (!payload || typeof payload.value !== "string") {
    return null;
  }
  const label = payload.value;
  const truncatedLabel = label.length > 20 ? label.substring(0, 20) + "..." : label;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={8} dx={-8} textAnchor="end" fill="#666" transform="rotate(-45)">
        {truncatedLabel}
      </text>
    </g>
  );
};

function BarChart({ data }: { data?: { count: number }[] }) {
  const maxValue = data ? Math.max(...data.map(item => item.count)) : 1;
  const tickValues = Array.from({ length: maxValue + 1 }, (_, i) => i);
  return (
    <ResponsiveContainer width="100%" height={500}>
      <RechartsBarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" height={150} tick={<CustomizedAxisTick />} interval={0} />
        <YAxis dataKey="count" ticks={tickValues} width={80} />
        <Tooltip />
        <Bar dataKey="count" fill="#82ca9d" />
      </RechartsBarChart>
    </ResponsiveContainer>
  );
}

export { BarChart };

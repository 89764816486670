import { Typography } from "antd";
import { ChatItem } from "../ChatItem";
import { IChat } from "../types";
import moment from "moment";

interface IChatListProps {
  chats: IChat[];
  handleSelectChat(args: { sessionId: number }): void;
  handleDelete(args: { sessionId: number }): Promise<void>;
}

const ChatList = ({ chats, handleSelectChat, handleDelete }: IChatListProps) => {
  function getDataCategory(data: string) {
    const formatterDate = moment(data, true);
    const today = moment();
    const yesterday = moment().subtract(1, "days");
    const sevenDaysAgo = moment().subtract(7, "days");
    const thirtyDaysAgo = moment().subtract(30, "days");
    const firstDayOfLastMonth = moment().subtract(1, "months").startOf("month");
    const lastDayOfLastMonth = moment().subtract(1, "months").endOf("month");

    if (formatterDate.isSame(today, "day")) {
      return "today";
    } else if (formatterDate.isSame(yesterday, "day")) {
      return "yesterday";
    } else if (formatterDate.isAfter(sevenDaysAgo)) {
      return "seven days ago";
    } else if (formatterDate.isAfter(thirtyDaysAgo)) {
      return "thirty days ago";
    } else if (formatterDate.isBetween(firstDayOfLastMonth, lastDayOfLastMonth, "day", "[]")) {
      return "last month";
    }
    return "over sixty days ago";
  }

  return (
    <div>
      {chats.length ? (
        chats.map((item, index) => {
          const date = getDataCategory(item.date);
          if (index === 0 || getDataCategory(chats[index - 1].date) !== date) {
            return (
              <div key={item.id}>
                <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
                  {date}
                </Typography.Text>
                <ChatItem
                  key={item.id}
                  email={item.email}
                  id={item.id}
                  subtitle={item.subtitle}
                  date={item.date}
                  isSelected={item.isSelected}
                  onClick={handleSelectChat}
                  onDelete={handleDelete}
                />
              </div>
            );
          } else {
            return (
              <ChatItem
                key={item.id}
                email={item.email}
                id={item.id}
                subtitle={item.subtitle}
                date={item.date}
                isSelected={item.isSelected}
                onClick={handleSelectChat}
                onDelete={handleDelete}
              />
            );
          }
        })
      ) : (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Typography color="#555">You don&apos;t have chats yet</Typography>
          <Typography color="#555">Create a new chat</Typography>
        </div>
      )}
    </div>
  );
};

export { ChatList };

import { Typography } from "antd";
import { ChatBar } from "../ChatBar";
import { NoChatContainer } from "./styled";
// PromptCard, PromptsContainer
// import { AppstoreOutlined } from "@ant-design/icons";
// import { useEffect, useState } from "react";
// import { IPrompt, promptsService } from "../../../services/prompts";

type NoChatContentProps = {
  isDesktop: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  // setNewMessagePrompt: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const NoChatContent = ({ setCollapsed, isDesktop }: NoChatContentProps) => {
  // const [prompts, setPrompts] = useState<IPrompt[]>([]);

  // useEffect(() => {
  //   (async () => {
  //     const topPrompts = await promptsService.getTop();
  //     setPrompts(topPrompts);
  //   })();
  // }, []);

  return (
    <>
      <ChatBar setCollapsed={setCollapsed} isDesktop={isDesktop} />
      <NoChatContainer>
        <Typography.Title level={3}>Select a chat on the left panel</Typography.Title>
        {/* {prompts.length ? (
          <PromptsContainer>
            <Typography.Text>
              <AppstoreOutlined />
            </Typography.Text>
            <Typography.Text className="initial_text">Prompts</Typography.Text>
            {prompts.slice(0, 3).map(prompt => (
              <PromptCard key={prompt.id} onClick={() => setNewMessagePrompt(prompt.prompt)}>
                <Typography.Title className="prompt_title" level={5}>
                  {prompt.title}
                </Typography.Title>
                <Typography.Text>{prompt.prompt}</Typography.Text>
              </PromptCard>
            ))}
          </PromptsContainer>
        ) : null} */}
      </NoChatContainer>
    </>
  );
};

export { NoChatContent };

import { Typography } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";
import { ChatBarContainer, InfoContainer } from "./styled";

type ChatBarProps = {
  isDesktop: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChatBar: React.FC<ChatBarProps> = ({ isDesktop, setCollapsed }: ChatBarProps) => {
  return (
    <ChatBarContainer>
      {!isDesktop ? (
        <MenuFoldOutlined
          onClick={() => setCollapsed(prev => !prev)}
          style={{ fontSize: "30px", width: "30px", height: "30px", color: "#3e4352" }}
        />
      ) : null}
      <InfoContainer>
        <Typography color="#777">Your chat</Typography>
      </InfoContainer>
    </ChatBarContainer>
  );
};
export { ChatBar };

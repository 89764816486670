/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from "./api";

const getResourceUsage = async (): Promise<any> => {
  const url = "/analytics/resource_usage";
  const response = await api.get(url);
  const data = response.data;
  return data;
};

const getPublisherUsage = async (): Promise<any> => {
  const url = "/analytics/publisher_usage";
  const response = await api.get(url);
  const data = response.data;
  return data;
};

export const analyticsService = {
  getResourceUsage,
  getPublisherUsage,
};

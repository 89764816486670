import { fetchEventSource } from "@microsoft/fetch-event-source";
import { api } from "./api";

export interface IReference {
  id: string;
  resource_id: string;
  title: string;
  score: number;
  text: string;
  url: string;
  publisher: string;
  publisher_id: number;
}

interface ITimestamped {
  id: number;
  created_at: string;
  updated_at: string;
}

export type IMessageStream = {
  delta: string;
  finish_reason: string | null;
  references?: IReference[];
};

export interface IConversation extends ITimestamped {
  session_id: string;
  project_id: number;
  created_by: number;
  name: string;
}

interface IMessage {
  user_query: string;
  ai_response: string;
  date_time: string;
  references: IReference[];
}

interface IChatList {
  project_id: number;
  title: string;
  id: number;
  created_at: string;
  user?: { email: string };
}

interface IChatDetail extends IChatList {
  conversation: IMessage[];
}

interface IDeleteChatResponse {
  success: boolean;
}

const deleteChat = async ({ sessionId }: { sessionId: number }): Promise<boolean> => {
  const url = `/chats/${sessionId}`;
  const response = await api.delete(url);
  const data: IDeleteChatResponse = response.data;
  return data.success || false;
};

const listChats = async (): Promise<IChatList[]> => {
  const url = "/chats";
  const response = await api.get(url);
  return response.data;
};

const sendMessage = async ({
  prompt,
  sessionId,
}: {
  prompt: string;
  sessionId: number;
}): Promise<IChatDetail> => {
  const url = `/chats/${sessionId}`;
  const response = await api.post(url, { prompt }, { timeout: 120_000 });
  return response.data;
};

const sendMessageStream = ({
  prompt,
  callback,
  sessionId,
}: {
  prompt: string;
  callback(_: IMessageStream): void;
  sessionId: number;
}): Promise<void> => {
  const url = `${api.defaults.baseURL}/chats/${sessionId}/stream`;
  // View package documentation: https://www.npmjs.com/package/@microsoft/fetch-event-source
  return new Promise<void>((resolve, reject) => {
    const ctrl = new AbortController();
    fetchEventSource(url, {
      openWhenHidden: true, // Needed to keep the connection alive when the user switches tabs
      signal: ctrl.signal,
      async onopen(response) {
        if (response.ok) {
          return; // everything's good
        } else {
          const data = await response.json();
          const errMsg = data.detail || "Unknown error";
          reject(new Error(errMsg));
          ctrl.abort();
        }
      },
      onclose() {
        resolve();
      },
      onmessage(msg) {
        if (!msg.data) {
          return;
        }
        const data: IMessageStream = JSON.parse(msg.data);
        if (data.references) {
          data.references = aggregateReferences(data.references);
        }
        callback(data);
      },
      method: "POST",
      headers: {
        "Content-Type": "application/json", // This is required for the server to parse the body
      },
      body: JSON.stringify({ prompt }),
    });
  });
};

const createNewChat = async (): Promise<IChatList> => {
  const url = "/chats";
  const response = await api.post(url, { name: "AI Bot" });
  const data: IChatList = response.data;
  return data;
};

const aggregateReferences = (references: IReference[]): IReference[] => {
  const total = references.reduce((prev: number, curr: IReference) => curr.score + prev, 0);

  const aggResults = references.reduce((prev: IReference[], curr: IReference): IReference[] => {
    const index = prev.findIndex(value => value.resource_id === curr.resource_id);
    if (index === -1) {
      prev.push({ ...curr, score: curr.score / total });
    } else {
      prev[index].score += curr.score / total;
    }
    return prev;
  }, []);

  aggResults.sort((a, b) => (a.score > b.score ? -1 : 1));
  return aggResults;
};

const retrieveChat = async ({ sessionId }: { sessionId: number }): Promise<IChatDetail> => {
  const url = `/chats/${sessionId}`;
  const response: { data: IChatDetail } = await api.get(url);
  response.data.conversation = response.data.conversation.map(cv => ({
    ...cv,
    references: aggregateReferences(cv.references),
  }));
  return response.data;
};

export const chatService = {
  listChats,
  sendMessage,
  createNewChat,
  retrieveChat,
  deleteChat,
  sendMessageStream,
};

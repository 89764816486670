import { LeftTopBar } from "./LeftTopBar";
import { ScrollChats } from "../../../pages/Chat/styled";
import { ChatList } from "../ChatList";
import { IChat } from "../types";
import Sider from "antd/es/layout/Sider";
import { Button, Typography } from "antd";
import { LoadingOutlined, PlusOutlined, LeftOutlined, LineChartOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { routesManager } from "../../../routes/routesManager";

type DrawerChatProps = {
  chats?: IChat[];
  handleNewChat(): Promise<void>;
  handleDelete: ({ sessionId }: { sessionId: number }) => Promise<void>;
  handleSelectChat: ({ sessionId }: { sessionId: number }) => void;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  collapsed: boolean;
  isDesktop: boolean;
};

const DrawerChat = ({
  chats,
  collapsed,
  isDesktop,
  handleNewChat,
  handleDelete,
  handleSelectChat,
  setCollapsed,
}: DrawerChatProps) => {
  const navigator = useNavigate();
  return (
    <Sider
      style={{
        position: isDesktop ? "sticky" : "fixed",
        zIndex: 1001,
        left: collapsed ? "-100%" : "0px",
        top: "0px",
        height: "100%",
        backgroundColor: "#FFF",
        borderRight: "1px solid #ddd",
      }}
      trigger={null}
      collapsible={!isDesktop}
      collapsed={collapsed}
      onCollapse={() => setCollapsed(prev => !prev)}
      width={isDesktop ? 300 : "85%"}
      collapsedWidth={isDesktop ? 300 : "85%"}
    >
      <LeftTopBar />
      <div style={{ padding: collapsed ? 0 : "12px", height: "calc(100svh - 85px)" }}>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            onClick={handleNewChat}
            size="large"
            icon={<PlusOutlined />}
            style={{ fontSize: "14px", margin: "10px 0" }}
          >
            <Typography.Text>New Chat</Typography.Text>
          </Button>
          <Button
            onClick={() => navigator(routesManager.getAnalyticsRoute())}
            size="large"
            icon={<LineChartOutlined />}
            style={{ fontSize: "14px", margin: "10px 0" }}
          >
            <Typography.Text>Analytics</Typography.Text>
          </Button>
        </div>
        <ScrollChats>
          {chats === undefined ? (
            <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
              <LoadingOutlined />
            </div>
          ) : (
            <ChatList
              chats={chats}
              handleDelete={handleDelete}
              handleSelectChat={e => {
                handleSelectChat(e);
                if (!isDesktop) setCollapsed(true);
              }}
            />
          )}
        </ScrollChats>
        {isDesktop ? null : (
          <Button
            type="primary"
            onClick={() => setCollapsed(prev => !prev)}
            shape="circle"
            style={{
              color: "black",
              border: "1px solid darkgrey",
              background: "white",
              position: "absolute",
              top: 52,
              right: collapsed ? 0 : -10,
            }}
            icon={<LeftOutlined size={1} />}
            size="small"
          />
        )}
      </div>
    </Sider>
  );
};

export { DrawerChat };

const getChatRoute = (): string => {
  return "/";
};

const getAnalyticsRoute = (): string => {
  return "/analytics";
};

export const routesManager = {
  getChatRoute,
  getAnalyticsRoute,
};

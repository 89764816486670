import { Modal, Statistic, Typography } from "antd";
import { IReference } from "../../../services";
import Link from "antd/es/typography/Link";

interface IReferencesModalProps {
  isOpen: boolean;
  references: IReference[];
}

const ReferencesModal = ({ isOpen, references }: IReferencesModalProps) => {
  return (
    <Modal title="Sources" open={isOpen} footer={null} width={1000} style={{ marginRight: 100 }}>
      <div onClick={e => e.stopPropagation()}>
        <Typography style={{ color: "#888", textAlign: "right" }}>Information share</Typography>
        {references.map((r, index) => {
          const score = (r.score * 100).toFixed(2);
          return (
            <div
              key={r.id}
              style={{
                padding: "5px 20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: index === references.length - 1 ? undefined : "1px solid #ddd",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Link href={r.url} target="_blank">
                  {r.title}
                </Link>
                <Typography style={{ color: "#aaa" }}>{r.publisher}</Typography>
              </div>
              <Statistic value={score} suffix="%" />
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export { ReferencesModal };
